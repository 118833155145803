import { useState } from 'react';
import './style.css';
import ProductDescription from '../ProductDescription';
import ProductLandsights from '../ProductLandsights';

function ContentSwitcher ({content = []}) {


    const [currentContent, setCurrentContent] = useState('tab-content-landsights');

    const getCurrentContent = () => {
        
        let currentContentComponent = <ProductDescription tabContent={content[0]}/>;

        if (currentContent === 'tab-content-description') {
            currentContentComponent = <ProductDescription tabContent={content[0]}/>
        }

        if (currentContent === 'tab-content-landsights') {
            currentContentComponent = <ProductLandsights tabContent={content[1]} />
        }
        console.log(currentContent);

        return currentContentComponent;
    }

    return(
                <div className='component-switcher'>
                    <ul className='tab-controls'>
                        <li>
                            <button className={ currentContent === 'tab-content-description' ? 'active' : '' } onClick={() => setCurrentContent('tab-content-description')} > 
                                Leírás
                            </button> 
                        </li>
                        <li>
                            <button className={ currentContent === 'tab-content-landsights' ? 'active' : '' } onClick={() => setCurrentContent('tab-content-landsights')}> 
                                Látnivalók
                            </button>
                        </li>
                    </ul>
                    <div className='tab-content-description'>
                       { getCurrentContent() }
                    </div>  
                </div>
    )
}

export default ContentSwitcher;
import { useState, useEffect } from 'react';
import './style.css';

function Shipping ({setCurrentStep, formData, setFormData}) {

    const shippingLands = [
        {value:'hu', label:'HU'},
        {value:'at', label:'AT'},
        {value:'de', label:'DE'}
    ];

    return (
        <section className='shipping-data'>
                <h2 className='title'>
                    Szállítási adatok
                </h2>
                <div className='checkout-form'>
                    <div className='form-row'>
                        <select 
                                className='form-control shipping-country' 
                                name='shipping-country'
                                value={formData.shippingCountry}
                                onChange={event => setFormData({...formData, shippingCountry: event.target.value})}
                            >
                                <option value='hu'> HU </option>
                                <option value='at'> AT </option>
                                <option value='de'> DE  </option> 
                        </select>
                    </div>
                    <div className='form-row-group'>
                        <div className='form-row'>
                                <label className='form-control-label'>
                                    Utca
                                </label>
                                <input 
                                    className='form-control' 
                                    type='text' 
                                    name='street'
                                    value={formData.street}
                                    onChange={event => setFormData({...formData, street: event.target.value})}
                                />
                        </div>
                        <div className='form-row house-number'>
                            <label className='form-control-label'>
                                Házszám
                            </label>
                            <input 
                                className='form-control' 
                                name='house-number'
                                type='number'
                                value={formData.houseNumber}
                                onChange={event => setFormData({...formData, houseNumber: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className='form-row-group'>
                        <div className='form-row zip-code'>
                                <label className='form-control-label'>
                                    Irányítószám
                                </label>
                                <input 
                                    className='form-control' 
                                    name='zip-code'
                                    type='number'
                                    value={formData.zipCode}
                                    onChange={event => setFormData({...formData, zipCode: event.target.value})}
                                />
                            </div>
                            <div className='form-row'>
                                    <label className='form-control-label'>
                                        Város
                                    </label>
                                    <input 
                                        className='form-control' 
                                        name='city'
                                        inputMode='numeric'
                                        type='text'
                                        value={formData.city}
                                        onChange={event => setFormData({...formData, city: event.target.value})}
                                    />
                            </div>
                    </div>
                    <div className='form-row-group'>
                        <div className='form-row'>
                                <label className='form-control-label'>
                                    Emelet (opcionális)
                                </label>
                                <input 
                                    className='form-control' 
                                    type='number' 
                                    name='floor'
                                    value={formData.floor}
                                    onChange={event => setFormData({...formData, floor: event.target.value})}
                                />                
                        </div>
                        <div className='form-row door-number'>
                            <label className='form-control-label'>
                                Ajtó (opcionális)
                            </label>
                            <input 
                                className='form-control' 
                                name='door-number'
                                type='number'
                                value={formData.doorNumber}
                                onChange={event => setFormData({...formData, doorNumber: event.target.value})}
                            />
                        </div>
                    </div>
                    <div className='step-navigation' >
                        <button className='component-navigation-button' onClick={() => setCurrentStep('customer-data')}>
                            Vissza
                        </button>
                        <button className='component-navigation-button' onClick={() => setCurrentStep('summary')}>
                            Tovább
                        </button>
                    </div>
                </div>
        </section>
    )

}

export default Shipping;
import { useEffect,useState } from 'react';
import './style.css';
import {Link} from 'react-router-dom';

function Articles () {

    const [articles, setArticles] = useState([]);

    const loadArticles = () => {
        fetch('/api/articles/index.json')
            .then(response => response.json())
            .then(data => setArticles(data.articles));
    };

    useEffect(() => {
        loadArticles();
    }, []);

    return (
        <section className='articles-screen'>
            <div className='container'>
                <div className='article-items'>
                    {articles.map((article, articleIndex) =>
                     <article className={'article-item' + ((articleIndex + 1) %2 === 0 ? ' reverse' : '')}>
                     <img className='article-item-image' src={article.imageUrl}/>
                     <div className='article-item-content'>
                         <h2 className='article-item-title'>
                            {article.title}
                         </h2>
                         <h3 className='article-item-headline'>
                            {article.headline}
                         </h3>
                         <Link to = {'/article/' + article.id}>
                         <button>
                             Tovább olvasok!
                         </button>
                         </Link>
                     </div>                       
                    </article>   
                    )};
                </div>
            </div>
        </section>
    );

};

export default Articles;
import { useEffect, useState } from 'react';
import './style.css'
import ContentSwitcher from '../../components/ContentSwitcher';
import { useParams } from 'react-router';

function ProductPage () {
    
    const { id } = useParams();
    console.log(id);

    const [currentImg, setCurrentImg] = useState(0);
    const [product, setProduct] = useState(
        { 
            name: '',
            price: '-', 
            backgroundImage:'/img/srilanka2.jpg', 
            images: [],
            travelTime: '-',
            description:'-',
            landsights:'-',
            headline:'-',
        }       
    );

    const loadProduct = () => {
        fetch('/api/products/' + id + '.json')
        .then(response => response.json())
        .then(data => setProduct(data))
    };

    useEffect(() => {
        window.scrollTo(0,0)
        loadProduct();
    }, []);

    const goToNextImage = () => {
        let newCurrentImage = currentImg + 1;

        if (newCurrentImage > product.images.length - 1) {
            newCurrentImage = 0;
        }

        setCurrentImg(newCurrentImage);
    }

    const goToPreviousImage = () => {
        let newCurrentImage = currentImg - 1;

        if (newCurrentImage < 0) {
            newCurrentImage = product.images.length - 1;
        }

        setCurrentImg(newCurrentImage);
    }



    return(
        <section className='product-page' style={{backgroundImage:'url(' + product.backgroundImage +')'}}>
            <div className='container'>
                    <h2 className='product-name'>
                        { product.name ? product.name : 'A terméket nem lehet betölteni.'}
                    </h2>
                <div id='main-img' className='product-main-content'>
                    <div className='product-image' style={{backgroundImage: 'url('+ product.images[currentImg] +')' }}>
                        <button onClick={() => goToPreviousImage()}>
                            {'<'}
                        </button>
                        <button onClick={() => goToNextImage()}>
                            {'>'}
                        </button>
                    </div>
                    <div className='product-information'>
                        <h2> Ár </h2>
                        <h3>
                            { product.price } HUF
                        </h3>
                        <p className='product-description'>
                            { product.headline }
                        </p>
                        <button className='cart-button'>
                            Kosárba teszem
                        </button>
                        <p className='shipping-information'>
                            Utazás időpontja
                        </p>
                        <p className='shipping-date'>
                            {product.travelTime}
                        </p>
                        <div className='shipping-description'>
                            <p className='shipping-services'>
                                GLS / DPD / Posta
                            </p>
                            <p className='shipping-price-title'>
                                Szállítási költség
                            </p>
                            <p className='shipping-price'>
                                1200 HUF
                            </p>
                        </div>
                    </div>
                </div>
                <div className='product-extra-content'>
                    { product.images.map((availableImage, availableImageIndex) => 
                        <div style={{backgroundImage: 'url('+ availableImage +')'}} onClick={() => setCurrentImg(availableImageIndex)}></div>
                    )}                 
                </div>
                <ContentSwitcher content={[product.description, product.landsights]}/>
            </div>            
        </section>
    )

}

export default ProductPage;
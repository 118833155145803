import './style.css';


function CartContent ({setCurrentStep, formData, setFormData, cartItems=[]}) {

    
    

    return(
        <section className='component-cart-content'>
            <h2 className='title'>
                Kosár tartalma
            </h2>
            <div className='checkout-form'>
                <div className='cart-item'>
                {cartItems.length === 0 ? (
                    <p> A kosár üres </p>
                        ) : (
                    <ul className='cart-item-list'>
                        {cartItems.map(cartItem => 
                            <li style={{backgroundImage:'url(/img/checkoutbg.jg)'}}>
                                <img className='cart-item-image' src={cartItem.imageUrl}/>
                                <span className='cart-item-name'>
                                    {cartItem.name}
                                </span>
                                <span className='cart-item-quantity'>
                                    {cartItem.quantity} DB
                                </span>
                                <span className='cart-item-price'>
                                    {cartItem.price * cartItem.quantity } HUF
                                </span>
                            </li>
                            )}
                    </ul>
                    )}
                </div>
                <div className='step-navigation'>
                    <button className='component-navigation-button' onClick={() => setCurrentStep('customer-data')}>
                        Tovább
                    </button>
                </div>
            </div>
        </section>
    )
};

export default CartContent;
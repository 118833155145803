import './style.css';
import { useState, useEffect } from 'react';


function CustomerData({setCurrentStep, formData, setFormData}) {


    const genders = [
        { value:'man', label:'Úr' },
        { value:'woman', label:'Hölgy' },
        { value:'other', label:'Egyéb' }
    ];


    return (
        <section className='customer-data'>
                <h2 className='title'>
                    Vásárlói adatok
                </h2>
                <div className='checkout-form'>
                    <div className='form-row-group'>
                        <div className='form-row'>
                                <select
                                    className='form-control' 
                                    type='text' 
                                    name='billingType'
                                    value={formData.billingType}
                                    onChange={event => setFormData({...formData, billingType: event.target.value})}
                                >
                                    <option value="private"> Magánszemély </option>
                                    <option value="company"> Cég </option>

                                </select>
                        </div>
                        <div className='form-row'>
                            <select 
                                    className='form-control' 
                                    name='gender-category'
                                >
                                    <option value='man'> Úr </option>
                                    <option value='women'> Hölgy </option>
                                    <option value='other'>  Egyéb </option> 
                            </select>
                        </div>
                    </div>
                    <div className='form-row-group'>
                        <div className='form-row'>
                                <label className='form-control-label'>
                                    Cégnév
                                </label>
                                <input 
                                    className='form-control' 
                                    type='text' 
                                    name='company-name'
                                    value={formData.companyName}
                                    onChange={event => setFormData({...formData, companyName: event.target.value})}
                                />
                        </div>
                        <div className='form-row'>
                                <label className='form-control-label'>
                                    Adószám
                                </label>
                                <input 
                                    className='form-control' 
                                    type='text' 
                                    name='tax-number'
                                    value={formData.taxNumber}
                                    onChange={event => setFormData({...formData, taxNumber: event.target.value})}
                                />
                        </div>
                    </div>
                    <div className='form-row-group'>
                        <div className='form-row'>
                                <label className='form-control-label'>
                                    Vezetéknév
                                </label>
                                <input 
                                    className='form-control' 
                                    type='text' 
                                    name='family-name'
                                    value={formData.familyName}
                                    onChange={event => setFormData({...formData, familyName: event.target.value})}
                                />
                        </div>
                        <div className='form-row'>
                                <label className='form-control-label'>
                                    Keresztnév
                                </label>
                                <input 
                                    className='form-control' 
                                    type='text' 
                                    name='first-name'
                                    value={formData.firstName}
                                    onChange={event => setFormData({...formData, firstName: event.target.value})}
                                />
                        </div>
                    </div>
                    <div className='form-row birth-date'>
                            <label className='form-control-label'>
                                Születési dátum (ÉÉÉÉ.HH.NN)
                            </label>
                            <input 
                                className='form-control' 
                                name='birth-date'
                                type='number'
                                value={formData.birthDate}
                                onChange={event => setFormData({...formData, birthDate: event.target.value})}                              
                            />
                    </div>
                    <div className='form-row-group'>
                        <div className='form-row'>
                                <label className='form-control-label'>
                                    E-mail
                                </label>
                                <input 
                                    className='form-control' 
                                    type='text'  
                                    name='email-address'
                                    label='E-mail'
                                    value={formData.emailAdress}
                                    onChange={event => setFormData({...formData, emailAdress: event.target.value})}  
                                />
                        </div>
                        <div className='form-row'>
                                <label className='form-control-label'>
                                    Mobil
                                </label>
                                <input 
                                    className='form-control' 
                                    name='mobile-number'
                                    type='number'
                                    value={formData.mobileNumber}
                                    onChange={event => setFormData({...formData, mobileNumber: event.target.value})}  
                                />
                        </div>
                    </div>
                    <div className='step-navigation' >
                        <button className='component-navigation-button' onClick={() => setCurrentStep('cart-content')}>
                            Vissza
                        </button>
                        <button className='component-navigation-button' onClick={() => setCurrentStep('shipping')}>
                            Tovább
                        </button>
                    </div>
                </div>
        </section>
    )
}

export default CustomerData;
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import  Home  from './screens/Home';
import Products from './screens/Products';
import Articles from './screens/Articles';
import DefaultLayout from './layouts/DefaultLayout';
import Article from './screens/Article';
import HomeWork from './screens/HomeWork';
import Competition from './screens/Competition';
import Contact from './screens/Contact';
import CheckOut from './screens/CheckOut';
import { useState } from 'react';
import ProductPage from './screens/ProductPage';
import ProductManagement from './screens/ProductManagement';
import Training from './screens/Training';
import Dashboard from './screens/Dashboard';



function App() {

// define empty array, list as default value for cart
let storedCart = [];

// if cart information has been found in local storage
if (localStorage.getItem('cart')) {
  console.log('cart has been found');
    /*
      save cart information as a variable, convert information from
      string to javascript data with JSON.parse method
    */ 
    const cartInformation = JSON.parse(localStorage.getItem('cart'));

  // check if cart information has items
  if (cartInformation && cartInformation.length) {
    storedCart = cartInformation;
  }

} else {
  console.log('there is no cart found in local storage');
}

const [cartItems, setCartItems] = useState(storedCart);

const addProductToCart = (product) => {
  console.log(product,cartItems);

  const productInCart = cartItems.find(cartItem => cartItem.id === product.id);
  let newCartItems = [...cartItems];

  
if (!productInCart) {
  newCartItems = [...cartItems, {...product, quantity: 1}];
  setCartItems(newCartItems)
} else {
    const selectedProduct = newCartItems.find(cartItem => cartItem.id === product.id);
    selectedProduct.quantity = selectedProduct.quantity + 1;
    setCartItems(newCartItems);
  }  

  /*  save cart items to local storage, so we can reach cart anytime,
      anywhere
      local storage can store only text
      JSON.stringify - converts data to string = objects to text
  */

  localStorage.setItem('cart', JSON.stringify(newCartItems));

};

return (
    <BrowserRouter>
      <Routes>
        <Route element={ <DefaultLayout cartItems={cartItems }/> }>
          <Route index element={ <Home /> } />
          <Route path={'products'} element={ <Products addProductToCart={addProductToCart} /> } />
          <Route path={'products/:id'} element={ <ProductPage/> } />
          <Route path={'product-management'} element={<ProductManagement/>} />
          <Route path={'home-work'} element={ <HomeWork/> } />
          <Route path={'articles'} element={ <Articles/> } />
          <Route path={'articles/:id'} element={<Article/>} />
          <Route path={'competition'} element={<Competition/>} />
          <Route path={'contact'} element={<Contact />} />
          <Route path={'checkout'} element={<CheckOut cartItems={cartItems} />} />
          <Route path={'training'} element={<Training />}/>
          <Route path={'dashboard'} element={<Dashboard />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import './style.css';
import {Link} from 'react-router-dom';

function Navigation({dropdownItems}) {

    const navigationItems = [

        { id:'home', label: 'KEZDŐLAP', path: '/'},
        { id:'products', label: 'TERMÉKEK', path: '/products'},
        { id:'contact', label: 'KAPCSOLAT', path: '/contact'},
        { id:'home-work', label: 'VÁROSOK', path: '/home-work'},
        { id:'articles', label: 'HÍREK', path: '/articles'},
        { id:'competition', label: 'NYEREMENYJÁTÉK', path:'/competition'},
        { id:'cart', label: 'KOSÁR', path: 'cart'}

    ];

    return (
    <nav className='main-navigation' style={{backgroundImage:'url(/img/checkoutbg.jpg)'}}>
            <div className='main-navigation-brand'>
                <img src='/img/logo.jpg' height={52} width={60}/>
            </div>
            <ul className='main-navigation-links'>
                { navigationItems.map((navigationItem) => 
                    <li key={navigationItem.id} className={navigationItem.id === 'cart' ? 'cart' : ''}>
                        <Link to={navigationItem.path}>
                            { navigationItem.id === 'cart' ? 
                                <img src='/img/carticon.jpg'/> : ''
                            }
                            { navigationItem.label }
                        </Link>
                        { navigationItem.id === 'cart' ? 
                                <div className='cart-dropdown'>
                                    <ul className='cart-dropdown-menu'>
                                        { dropdownItems.map(dropdownItem =>
                                        <li key={dropdownItem.id}>
                                            <span className='cart-item-name'>
                                                { dropdownItem.name }
                                            </span>
                                            <span className='cart-item-quantity'>
                                                { dropdownItem.quantity } db
                                            </span>
                                            <span className='cart-item-price'>
                                                { dropdownItem.price * dropdownItem.quantity} {dropdownItem.currency}
                                            </span>
                                        </li>
                                        )}
                                        <li className='checkout-button'>
                                            
                                                <button className='button'>
                                                    Tovább a kosárba!
                                                </button>
                                            
                                        </li>
                                    </ul>
                                </div>                               
                                :
                                ''
                            }
                    </li>
                )}
            </ul>
    </nav>
  )
}

export default Navigation;
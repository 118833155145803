import './style.css';

function ProductManagement () {

return(
    <section className='product-management-screen' style={{backgroundImage: 'url(/img/checkoutbg.jpg)'}}>
        <div className='container'>
            <h1 className='title'>
                Product upload
            </h1>
            <div className='product-content-container'>
                <div className='product-block-left'>
                    <h3 className='headline'>
                        Product Upload Form
                    </h3>
                    <div className='data-fill'>
                        <div className='form-group'>
                            <label>
                                Név
                            </label>
                            <input type='text' name='name'/>
                        </div>
                        <div className='form-group'>
                            <label>
                                Kategória
                            </label>
                            <select  type='text' name='name'>
                                <option value='snorkelling'> Sznorkelezés </option>
                                <option value='srilanka' selected> Sri Lanka körutazás </option>
                            </select>                          
                        </div>
                        <div className='form-row-group'>
                            <div className='form-group'>
                                <label>
                                    Ár
                                </label>
                                <input type='text' name='name'/>
                            </div>
                            <div className='form-group'>
                                <label>
                                    Pénznem
                                </label>
                                <input type='text' name='name'/>
                            </div>
                        </div>
                    </div>
                    <button className='button'>
                        Termék feltöltése
                    </button>
                </div>
                <div className='product-block-right'>
                    <h3 className='headline'>
                        Product Image
                    </h3>
                    <div className='image-wrapper'>
                        <img className='uploaded-image' src='/img/homecover.jpg'/>
                    </div>
                    <input className='file-upload-input' type='file' name='image'/>
                </div>
            </div>
        </div>
    </section>
)
}

export default ProductManagement;
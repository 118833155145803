import './style.css';
import { Link } from 'react-router-dom';

function Cover ({title, headline, imageUrl}) {

    const scrollToAboutMe = () => {
        document.getElementById("about-me").scrollIntoView({behavior:'smooth'});
    }

    return (
        <section className='cover-component' style={ imageUrl={backgroundImage:'url(/img/homecover.jpg)'} } >
            <div className='container'>
                <h1 className='cover-component-title'>
                    {title}
                </h1>
                <h2 className='cover-component-headline'>
                    {headline}
                </h2>
                
                <button className='cover-component-button' onClick={() => scrollToAboutMe()}>
                    Rólam!
                </button>
            </div>
        </section>
    )
}

export default Cover;
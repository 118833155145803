import { useState, setState, useEffect } from 'react';
import './style.css';
import CustomerData from '../../components/CustomerData';
import Shipping from '../../components/Shipping';
import CheckOutSummary from '../../components/CheckoutSummary';
import CartContent from '../../components/CartContent';

function CheckOut ({cartItems, title}) {
    
    const steps = [
        { id:'cart-content', order:'1', label:'Kosár tartalma' },
        { id:'customer-data', order:'2', label:'Vásárlói adatok' },
        { id:'shipping', order:'3', label:'Szállítási adatok' },
        { id:'summary', order:'4', label:'Rendelés összegzése' }
    ];

    const [currentStep, setCurrentStep] = useState('cart-content');
    const [formData, setFormData]  = useState({})

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    const getCurrentStepContent = () => {
        
        let currentStepContent = <CustomerData formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep}/>;

        if (currentStep === 'cart-content') {
            currentStepContent = <CartContent cartItems={cartItems} setCurrentStep={setCurrentStep}/>
        }

        
        if (currentStep === 'shipping') {
            currentStepContent = <Shipping 
                    setCurrentStep={setCurrentStep}
                    cartItems={cartItems}/>
        }

        if (currentStep === 'summary') {
            currentStepContent = <CheckOutSummary setCurrentStep={setCurrentStep}/>
        }

        
        return currentStepContent;

    }

    return (
    
    <section className='checkout-screen' style={{backgroundImage:'url(/img/checkoutbg.jpg)'}}>
        <div className='container'>
            <ul className='checkout-steps-tags'>
                { steps.map(step => 
                <li key={step.id}> 
                    <div className={'tag-circle '  + (currentStep === step.id ? 'active' : '')}>
                        <span> {step.order} </span>
                    </div>
                    <p className='tag-caption'>
                            {step.label}
                    </p>
                </li>
                )
                }
            </ul>

            <div className='current-step-content'>
                { getCurrentStepContent() }
            </div>

        </div>
    </section>
    
    )
};

export default CheckOut;
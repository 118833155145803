import { useEffect, useState } from 'react';
import GameElement from '../../components/GameElement';
import './style.css';

function Competition () {

    const [wonGame, setWonGame] = useState(false);



    const availableImages = [
        '/img/dolphin.jpg',
        '/img/shark.jpg',
        '/img/octopus.jpg',
        '/img/clownfish.jpg'
    ];

    const [gameElements, setGameElements] = useState([
        [
            { backgroundImage: availableImages[0] },
            { backgroundImage: availableImages[1] },
            { backgroundImage: availableImages[2] },
            { backgroundImage: availableImages[3]}
        ],
        [
            { backgroundImage: availableImages[3] },
            { backgroundImage: availableImages[2] },
            { backgroundImage: availableImages[1] },
            { backgroundImage: availableImages[0]}
        ],
        [
            { backgroundImage: availableImages[3] },
            { backgroundImage: availableImages[0] },
            { backgroundImage: availableImages[2] },
            { backgroundImage: availableImages[1]}
        ],
        [
            { backgroundImage: availableImages[0] },
            { backgroundImage: availableImages[2] },
            { backgroundImage: availableImages[3] },
            { backgroundImage: availableImages[1]}
        ]
        
        
    ]);

    //return random image URL from the available images
    const getRandomImage = (currentImageUrl) => {
        //create vairable for the new image URL
        let newImageUrl = '';
        //execute code until we don't get an image that differs from the currentImageUrl
        while (!newImageUrl || newImageUrl === currentImageUrl) {
            //generate random number for imageIndex options:0,1,2,3
            const randomImageIndex = Math.floor(Math.random() * availableImages.length)

            //set new image URL by the generated indey
            newImageUrl = availableImages[randomImageIndex];

            return newImageUrl;

        }

        return availableImages[1]
    }

    const changeElement = (rowIndex,gameElementIndex) => {
        //create a copy of the original game elements
        const newGameElements = [...gameElements];
        //find role and element by index
        newGameElements[rowIndex][gameElementIndex].backgroundImage = getRandomImage(newGameElements[rowIndex][gameElementIndex].backgroundImage);
        //set value of gameElements variable
        setGameElements(newGameElements);
    };

    //check the state of the game and change the screen based on the result
    const checkGameState = () => {
        console.log('változott a játék');
        let won = false;
        
        won = gameElements[0].every(element => element.backgroundImage === availableImages[0]);

        /*
        if(won) {
           won = gameElements[1].every(element => element.backgroundImage === availableImages[1]);
        }

        if(won) {
           won = gameElements[2].every(element => element.backgroundImage === availableImages[2]);
        }

        if(won) {
           won = gameElements[3].every(element => element.backgroundImage === availableImages[3]);
        }
        */
        setWonGame(won);
    }



    useEffect(() => {
        checkGameState();
    });



    return(
        <section className={'competition-screen ' + (wonGame ? 'won-screen' : '')} style={{ backgroundImage: wonGame ? 'url(/img/winnerbg.jpg' : 'url(/img/underwater.jpg'}}>
            <div className='container'>
                { wonGame ? 
                <div className='won-state'>
                    <h1 className='title'>
                        GRATULÁLUNK!
                    </h1>
                    <h2 className='headline' style={{backgroundImage: wonGame ? 'url(/img/ribbon.png)' : ''}}>
                        {wonGame ? 
                        <span className='headline-won-state'><small>Megnyerted az 50%-os kupont!</small> A nyertes kuponkódot és a felhasználásával kapcsolatos információkat <br/> a megadott e-mail címre továbbítjuk!</span> : 'Nyereményjáték'}
                    </h2>
                </div>
                :
                <>
                <h1 className='title'>
                    {wonGame ? 'GRATULÁLUNK!' : 'Nyereményjáték'}
                </h1>
                <h2 className='headline'>
                    A képekre kattintva össze kell gyűjtenie egy sorban négy db azonos kártyát!
                </h2>
                <div className='game-area'>
                    { gameElements.map((row, rowIndex) =>
                        <div key={rowIndex} className='game-row'>
                            { row.map((gameElement,gameElementIndex) => 
                                <GameElement
                                    key={gameElementIndex}
                                    changeElement={() => changeElement(rowIndex,gameElementIndex)}
                                    backgroundImage={ gameElement.backgroundImage } />
                            )}                         
                        </div>
                    )}
                </div>
                </>
                }
            </div>
        </section>
    );
};

export default Competition;
import { Link } from 'react-router-dom';
import './style.css';
import { useState, useEffect } from 'react';

function Products({addProductToCart}) {
    //store result items in an array, default state is an empty list.
    const [resultItems, setResultItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [formData, setFormData] = useState({ name: '', color:''})

    const colors = [
        { label:'Összes', value:''},
        { label:'Piros', value:'red' },
        { label:'Kék', value:'blue' },
        { label:'Zöld', value:'green'}
    ];
   
    const search = () => {
        console.log(formData);
        let foundItems = [...products];

        //collect products by name
        if (formData.name) {
            foundItems = foundItems.filter(product => product.name.toLowerCase().includes(formData.name.toLowerCase()));
        }
        if (formData.categoryId) {
            foundItems = foundItems.filter(product => product.categoryId === formData.categoryId);
        }

        if (formData.priceMin && !isNaN(formData.priceMin) && formData.priceMin > -1) {
            foundItems = foundItems.filter(product => product.price >= formData.priceMin)
        };

        if (formData.priceMax && !isNaN(formData.priceMax) && formData.priceMax > -1) {
            foundItems = foundItems.filter(product => product.price <= formData.priceMax)
        };

        if (formData.color) {
            foundItems = foundItems.filter(product => product.color === formData.color)
        };

        setResultItems(foundItems);
    };

    const loadProducts = () => {
        fetch('/api/products/index.json')
        .then(response => response.json())
        .then(data => {
            setProducts(data.products);
            setResultItems(data.products);
        });
    };

    useEffect(() => {
        window.scrollTo(0,0);
        loadProducts();
    }, []);

    useEffect (() => {
        search();
    }, [formData]);

    return (
        <div className='component-products-screen'>
            <div className='component-products-search'>
                <aside className='filters'>

                    <h2 className='title' style={{backgroundImage: 'url(/img/filterbg.jpg)'}}>
                        Keresés
                    </h2>

                    <div className='form-group'>
                        <label className='form-control-label'>
                            Név
                        </label>
                        <input 
                            className='form-control' 
                            type='text' 
                            name='name'
                            value={formData.name}
                            onChange={event => setFormData({...formData, name: event.target.value})}
                        />
                    </div>

                    <div className='form-group'>
                        <label className='form-control-label'>
                            Kategória
                        </label>
                        <select 
                            className='form-control' 
                            name='categoryId'
                            value={formData.categoryId}
                            onChange={event => setFormData({...formData,categoryId: event.target.value})}
                        >
                            <option value=''> Összes </option>
                            <option value='activity'> Extrém kaland </option>
                            <option value='culture'> Kultúra </option> 
                            <option value='gastronomy'> Gasztronómia </option> 
                        </select>
                    </div>  

                    <div className='form-group'>
                        <label className='form-control-label'>
                            Ár min.
                        </label>
                        <input 
                            className='form-control' 
                            type='text' 
                            name='priceMin'
                            value={formData.priceMin}
                            onChange={event => setFormData({...formData, priceMin: event.target.value})}
                        />
                    </div>

                    <div className='form-group'>
                        <label className='form-control-label'>
                            Ár max.
                        </label>
                        <input 
                            className='form-control'
                            type='text' 
                            name='priceMax'
                            value={formData.priceMax}
                            onChange={event => setFormData({...formData, priceMax: event.target.value})}
                        />
                    </div>

                    <div className='form-group'>
                        <label className='form-control-label'>
                            Szín
                        </label>
                    
                    { colors.map(color => 
                        <label key={color.value} className='radio-button'>
                            <input 
                                className='radio-button-control' 
                                checked={formData.color === color.value} 
                                value={color.value} 
                                type='radio' 
                                name='color'
                                onChange={event => setFormData({...formData, color: color.value})}                          
                            />                  
                            <span>
                                { color.label }
                            </span>
                        </label>
                    )}
                    </div>

                    <div className='form-group'>
                        <label className='form-control-label'>
                            Típus
                        </label>
                        
                        <div className='checkboxes'>

                            <label className='form-control-checkbox'>
                                <input className='checkbox-control' type='checkbox' name='intensiveStudies'/>
                                <span>
                                    Intenzív (3-6 hónapos) képzés
                                </span>
                            </label>

                            <label className='form-control-checkbox'>
                                <input className='checkbox-control' type='checkbox' name='longCourses'/>
                                <span>
                                    Részletes (6-9 hónapos) képzés
                                </span>
                            </label>

                            <label className='form-control-checkbox'>
                                <input className='checkbox-control' type='checkbox' name='childrenCourses'/>
                                <span>
                                    Gyerek (3 hónapos) képzés
                                </span>
                            </label>
                            
                        </div>
                    </div>

                    <div className='form-group'>
                        <button className='filter-button' onClick={ () => search() }>
                            Szűrés
                        </button>
                    </div>
                </aside>
                
                <div className='result-items'>
                    <ul className='result-item-list'>
                        {resultItems.map((resultItem) => 
                            <li key={resultItem.id} className='result-item'>
                                <div className='result-item-media' style={{backgroundImage: 'url(' + resultItem.imageUrl + ')' }}></div>
                                <div className='result-item-content'>                                  
                                    <h1 className='result-item-name'>
                                        <Link to={'/products/' + resultItem.id }>
                                            {resultItem.name}
                                        </Link>
                                    </h1>                              
                                    <h2 className='result-item-headline'>
                                        {resultItem.headline}
                                    </h2>
                                    <p className='result-item-price'>
                                        {resultItem.price} {resultItem.currency}
                                    </p>
                                    
                                    <button className='result-item-button' onClick={() => addProductToCart(resultItem)}>
                                        Kosárba teszem!
                                    </button>
                                   
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>

        </div>
    );
}
export default Products;
import { useState } from 'react';
import './style.css';

function NewEmployee ({addNew}) {
    
    const [formData, setFormData] = useState({})

    return(
        <form>
            <form className='component-new-employee'>
                <h2 className='headline'>
                    New Employee
                </h2>

                <div className='form-group'>
                    <label className='form-label'>
                        First Name
                    </label>
                    <input 
                        type='text' 
                        name='firstName'
                        onChange={(event) => setFormData({...formData, firstName: event.target.value })}
                    />
                </div>
                <div className='form-group'>
                    <label className='form-label'>
                        Last Name
                    </label>
                    <input 
                        type='text' 
                        name='firstName'
                        onChange={(event) => setFormData({...formData, lastName: event.target.value })}
                    />
                </div>
                <div className='form-group'>
                    <label className='form-label'>
                        E-Mail
                    </label>
                    <input 
                        type='email' 
                        name='firstName'
                        onChange={(event) => setFormData({...formData, email: event.target.value })}
                        
                    />
                </div>
                <button onClick={(event) => {event.preventDefault(); addNew(formData); }}> 
                    Add Member 
                </button>
            </form>
        </form>
    )

};

export default NewEmployee;
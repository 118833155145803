import { useEffect, useState } from 'react';
import './style.css';

function SimpleCalculator () {

    const [result, setResult] = useState(6);
    // az első mező értékét a numberOne változóba mentjük a setNumberOne funkció segítségével
    const [numberOne, setNumberOne] = useState(1);
    const [numberTwo, setNumberTwo] = useState(2);
    const [numberThree, setNumberThree] = useState(3);
    


    const onNumberChange = (order, event) => {
        // az az érték, amit beír a felhasználó a mezőbe 
        //amit a parseInt segítségével szöveget számmá alakítjuk
        const value = parseInt(event.target.value);

        if (order === 1) {
            setNumberOne(value);
        }

        if (order === 2) {
            setNumberTwo(value);
        }
    
        if (order === 3) {
            setNumberThree(value)
        }
    }

    /* 
        reactos funkció, ami segít egy akciót végrehajtani / funkciót futtatni, 
        akkor ha a komponensünk egyik változójának értéke felülírásra kerül.
        például számolni fog, ha a numberOne változó , azaz az első beviteli mező értéke változik 
        2 paramétere van, az első, hogy mit csináljon, azaz egy funkció, a második, hogy melyik változókat figyelje
    */
    useEffect( () => {setResult(numberOne + numberTwo + numberThree)}, [numberOne,numberTwo,numberThree]);

    return (
        <div className='calculator-datafill'>
            <div className='form-group-section'>
                <div className='form-group'>
                    <label>
                        Szám 1
                    </label>
                    <input 
                        type='number'
                        value={numberOne}
                        onChange={(event) => onNumberChange( 1, event )}
                    >
                    </input>
                </div>
                <div className='form-group'>
                    <label>
                        Szám 2
                    </label>
                    <input 
                        type='number'
                        value={numberTwo}
                        onChange={(event) => onNumberChange( 2, event )}
                    >
                    </input>
                </div>
                <div className='form-group'>
                    <label>
                        Szám 3
                    </label>
                    <input 
                        type='number'
                        value={numberThree}
                        onChange={(event) => onNumberChange( 3, event )}
                    >
                    </input>
                </div>
            </div>
            <h3> Végeredmény:{result} </h3>
        </div>
    )
}

export default SimpleCalculator;
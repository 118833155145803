import './style.css';
import Table from '../../components/Table';
import Navigation from '../../components/Navigation';
import Cover from '../../components/Cover';
import Features from '../../components/Features';

function Home() {
  
return (
    <div className="home-screen">
        <Cover title={'Üdvözlom a portfólióm oldalán!'} headline={'Az oldalon az általam elsajátított szerkezeti elemeket szeretném bemutatni!'} />
        <Features />
        <Table />
    </div>
  );
}

export default Home;
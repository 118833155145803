import CustomerData from '../CustomerData';
import Shipping from '../Shipping';
import './style.css';
import { useState, useEffect } from 'react';


function CheckOutSummary({setCurrentStep, formData, setFormData}) {

    return (
        <section className='checkout-summary'>
            <h2 className='title'>
                Rendelés összegzése
            </h2>
                <div className='checkout-form'>
                    <div className='checkout-form-blocks'>
                        <div className='customer-data-summary'>
                            <h2> Személyes adatok </h2>
                        <div className='form-row'>
                            <p> Úr </p>
                        </div>
                        <div className='form-row-group'>
                            <div className='form-row'>
                                    <label className='form-control-label'>
                                        Vezetéknév
                                    </label>
                                    <p> Sárosi </p>
                            </div>
                            <div className='form-row'>
                                    <label className='form-control-label'>
                                        Keresztnév
                                    </label>
                                    <p> Soma </p>
                            </div>
                            <div className='form-row birth-date'>
                                <label className='form-control-label'>
                                    Születési dátum (ÉÉÉÉ.HH.NN)
                                </label>
                                <p> 1999.12.09. </p>
                            </div>
                            <div className='form-row'>
                                    <label className='form-control-label'>
                                        E-mail
                                    </label>
                                    <p> sarosisoma9@gmail.com </p>
                            </div>
                            <div className='form-row'>
                                    <label className='form-control-label'>
                                        Mobil
                                    </label>
                                    <p>+36206629424</p>
                            </div>
                        </div>
                        </div>
                        <div className='shipping-summary'>
                            <div className='shipping-data'>
                                <h2 className='title'>
                                    Szállítási adatok
                                </h2>
                                <div className='form-row'>
                                    <p> HU </p> 
                                </div>
                                <div className='form-row-group'>
                                    <div className='form-row'>
                                            <label className='form-control-label'>
                                                Utca
                                            </label>
                                            <p>Levél u.</p>
                                    </div>
                                    <div className='form-row house-number'>
                                        <label className='form-control-label'>
                                            Házszám
                                        </label>
                                        <p> 16. </p>
                                    </div>
                                    <div className='form-row zip-code'>
                                            <label className='form-control-label'>
                                                Irányítószám
                                            </label>
                                            <p> 2600 </p>
                                    </div>
                                    <div className='form-row city'>
                                            <label className='form-control-label'>
                                                Város
                                            </label>
                                            <p> Vác </p>
                                    </div>
                                    <div className='form-row'>
                                            <label className='form-control-label'>
                                                Emelet (opcionális)
                                            </label>
                                            <p> - </p>
                                    </div>
                                    <div className='form-row door-number'>
                                        <label className='form-control-label'>
                                            Ajtó (opcionális)
                                        </label>
                                        <p> - </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='step-navigation' >
                        <button className='component-navigation-button' onClick={() => setCurrentStep('shipping')}>
                            Vissza
                        </button>
                        <button className='component-navigation-button' onClick={() => setCurrentStep()}>
                            Fizetés
                        </button>
                    </div>

                </div>
        </section>
    )
}

export default CheckOutSummary;
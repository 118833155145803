import Cover from '../Cover';
import './style.css';


function Features() {

    const features = [
        {title: 'Say no to plastic!', description: 'Avoid plastic at home!', imageUrl:'/img/productsbg.jpg'},
        {title: 'Stay green!', description: 'Grow plants!', imageUrl:'/img/featureball2.jpg'},
        {title: 'Protect animals!', description: 'Love them like yours!', imageUrl:'/img/elephant.jpg'}
    ];
    
    return (
        <section className='features'>
            <div className='container'>
                <ul className='feature-list'>
                    {features.map(feature => 
                    <li key={feature.title} className='feature'> 
                            <div className='img-wrap'>
                                <div className='image' style={{ backgroundImage: 'url('+ feature.imageUrl +')'}}>                         
                                </div> 
                            </div>                   
                            <h2 className='title'> 
                                {feature.title}
                            </h2>
                            <p className='description'>
                                {feature.description}
                            </p>   
                    </li> 
                    )
                    }          
                </ul>
            </div>
        </section>
    )
};

export default Features;
import { useEffect, useState } from 'react';
import './style.css'
import { useParams } from 'react-router';

function Article () {
    const {id} = useParams();
    
    const [article, setArticle] = useState({});

    const loadArticles = () => {
        fetch('/api/articles/' + id + '.json')
        .then(response => response.json())
        .then(data => setArticle(data));
    };

    useEffect(() => {
        window.scrollTo(0,0);
        loadArticles();
    }, []);

    return(
        <section className='article-screen'>
            <div className='article-headline-box'style={{backgroundImage: 'url('+ article.imageUrl +')'}}>
                    <h1 className='article-headline'>
                        { article.title }
                    </h1>
            </div>
            <div className='container'>
                <article className='main-article'>
                    <h3 className='article-text-headline'>
                        { article.headline}
                    </h3>
                    <div className='description'>
                        <p>
                          { article.description }  
                        </p>
                    </div>
                </article>
            </div>
        </section>
    );
};

export default Article;
import './style.css';

function GameElement( {backgroundImage, changeElement} ) {
    return(
        <div className='game-element' 
        style={{backgroundImage: 'url(' + backgroundImage + ')' }}
        onClick={changeElement}
        >
        </div>
    )
}

export default GameElement;
import { Outlet } from 'react-router';
import Navigation from '../../components/Navigation';

function DefaultLayout({cartItems}) {
  
    return (
        <div className='default-layout'>
            <Navigation dropdownItems={cartItems}/>
            <Outlet />
        </div>
      );
    }
    
    export default DefaultLayout;

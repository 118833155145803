import './style.css'

function ProductLandsights ({tabContent=''}) {
    return (
        <article className='description-box' style={{backgroundImage:'url(/img/srilanka5.jpg)'}}>
                    <h3 className='description-box-title'>
                        Látnivalók
                    </h3>
                    <p>
                        { tabContent } 
                    </p>
                    <div className='features-list'>
                        <div className='feature'>
                            <p className='feature-title'>
                                Exkluzív szállás
                            </p>
                            <img src='/img/houselogo.png'/>
                            <p className='feature-description'>
                                Kedvezményes szállásokért kattintson a házra!
                            </p>
                        </div>
                        <div className='feature'>
                            <p className='feature-title'>
                                Tuk Tuk bérlés
                            </p>
                            <img src='/img/tuktuk.png'/>
                            <p className='feature-description'>
                                Mindig is ki szerette volna próbálni a tuktukot? Kedvezményes árainkért kattintson a tuktukra!
                            </p>
                        </div>
                        <div className='feature'>
                            <p className='feature-title'>
                                Exkluzív szállás
                            </p>
                            <img src='/img/surflogo.png'/>
                            <p className='feature-description'>
                                Lovagolja meg az Indiai-óceán hullámait! Surf iskolánk garantálja, hogy ne ússza meg szárazon!
                            </p>
                        </div>
                    </div>
        </article>
    )
}

export default ProductLandsights;
import './style.css';

function HomeWork () {

    const awesomePictures = [
        {title:'Coppenhagen', description:'Denmark',imageUrl:'/img/coppenhagen.jpg'},
        {title:'Singapore', description:'Singapore',imageUrl:'/img/singapore.jpg'},
        {title:'Calgary', description:'Canada',imageUrl:'/img/calgary.jpg'},
        {title:'Helsinki', description:'Finland',imageUrl:'/img/helsinki.jpg'},
        {title:'Auckland', description:'New-Zealand',imageUrl:'/img/auckland.jpg'}
    ];


    return(
        <section className='home-work'>
            <div className='container'>
                <h1 className='headline'>
                    TOP 5 World's most cleanest cities
                </h1>
                <ul className='city-cards'>
                    {awesomePictures.map(awesomePicture => 
                        <li className='cities'>
                            <div className='city-img' style={{backgroundImage:'url('+ awesomePicture.imageUrl +')'}}>
                            </div>
                            <h1 className='title'>
                                { awesomePicture.title }
                            </h1>
                            <p className='description'>
                                { awesomePicture.description }
                            </p>
                        </li>
                    )
                    }
                </ul>
            </div>
        </section>
    )
};


export default HomeWork;
import './style.css';
function Table () {

const myName = 'Soma Sárosi'; //string
const myAge = 24; //number
const hasPet = false; //boolean
const mySkills = [
  'HTML', 'CSS', 'Javascript alapok','ES6','REACT alapok', 'UI/UX design alapok' 
];

const me = {
  languages:'Enlish, German',
  numberOfChildren: undefined, 
  name: myName,
  age: myAge,
  hasPet,
  skills: mySkills,
};
    return (
      <section id='about-me' style={{backgroundImage:'url(/img/trainingcover.jpg)'}}>
      <table className='demo-table'>
        <thead>
          <tr>

            <th> About me </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> Name: </td>
            <td> {myName} </td>
          </tr>
          <tr>
            <td> Age: </td>
            <td> { me.age } éves </td>
          </tr>
          <tr>
            <td> Languages: </td>
            <td> {me.languages} </td>
          </tr>
          <tr>
            <td> Gyerekek száma: </td>
            <td> {me.numberOfChildren ? me.numberOfChildren : 'nincs' }</td>
          </tr>         
          <tr>
            <td> Van-e állata: </td>
            <td> { me.hasPet ? 'van' : 'nincs' } </td>
          </tr>
          <tr>
            <td> 
              Skills:
            </td>
            <td> 
              { me.skills.map(skill => 
                <p key={ skill }>
                  { skill }
                </p>
             )}
            </td>
          </tr>
        </tbody>
      </table> 
      </section>
    );
}

export default Table;

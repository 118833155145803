import { useRef, useState } from 'react';
import './style.css';
import SimpleCalculator from '../../components/SimpleCalculator';

function Training () {

    /*
        készítsetek egy változót labdak néven aminek az értéke legyen 
        egy tömb a következő elemmekkel
        'piros', 'zöld', 'kék', 'piros'
    */

        const labdak = [
            'piros',
            'zöld',
            'kék',
            'piros'
        ]
    /*
        készítsetek egy változót pirosLabdak néven, és szűrjétek le a ladbákat csak a piros labdákra, 
        tehát a tömbbnek csak a piros labdákat szabad tartalmaznia.
    */
        const pirosLabdak = labdak.filter(labda => labda === 'piros');

        console.log(pirosLabdak)

        const nevek = ['Soma', 'Gergő', 'Gergő'];

        // szűrni = halmazt kapsz vissza, több elemet is tartalmazhat
        const Gergok = nevek.filter(nev => nev ===  'Gergő');
        console.log(Gergok); // ['Gergő', 'Gergő'];

        // keresni = csak egy elemet kaphatunk vissza, vagy semmit mert nem találta meg amit kerestünk
        const keresettNev = nevek.find(nev => nev === 'Soma');
        console.log(keresettNev); // Soma

        // szamokat tartalmazó lista , tömb , angolul array.
        const szamok = [1, 2, 3];
        const szamokNagyobbakEgynel = szamok.filter(szam => szam > 1);
        console.log(szamokNagyobbakEgynel); // [2,3]

        const ksebbVagyEgyenloMintHarom = szamok.filter(szam => szam <= 3);
        console.log(ksebbVagyEgyenloMintHarom); // [1,2,3]

    /*
        készíts egy változót numbers néven, aminek 3 eleme legyen a 4, 8 , 12;
    */

        const numbers = [4, 8, 12];
    
    /*
        készíts egy változót smallerThanTen néven és szűrd le azokat a számokat, amelyek kisebbek mint 10
    */

        const smallerThanTen = numbers.filter(number => number < 10);
    
    
    /*
        készíts egy változót numberEight néven és keressük meg a numbers array-be azt az elemet ami a 8-al egyenlő
    */

        const numberEight = numbers.find(number => number === 8);

    /* 
        készíts egy változót people néven, ami egy array legyen, és ami objektumokat tartalmaz
    */

        const people = [
            { name: 'Peter' , age: '45'},
            { name: 'Peter' , age: '34'},
            { name: 'Gergo' , age: '34'}
        ];

        const peters = people.filter(person => person.name === 'Peter' );

    /* 
      Készítsünk egy változót oldBlokePeter néven amibe találjuk (find) 
      azt az egy embert akinek az életkora 45 ÉS a neve az, hogy Peter
    */

        const oldBlokePeter = people.find(person => person.age === 45 && person.name === 'Peter');

    /*
      Készítsünk egy változót mixedPeople néven amibe szűrjük le azokat az embereket,
      akik 34 vagy 45 évesek
    */

        const mixedPeople = people.filter(person => person.age === 34 || person.age === 45);

    const esikAzEso = true;
    let vanEsernyod = true;

    // ha esikAzeso = true és vanEseryőd true nem ázol el
    if (esikAzEso && vanEsernyod) {
        console.log('Nem ázol el');
    }

    vanEsernyod = false;

    if (esikAzEso && vanEsernyod === false) {
        console.log('Elázol. Megszívtad. Mint mindig.');
    }

    const sutANap = true;

    if (sutANap || vanEsernyod) {
        console.log('boldog vagy')
    }

    if (sutANap || (esikAzEso && vanEsernyod)) {
        console.log('Szerencsés vagy')
    }

    /*
        készítsetek egy változót isRaining néven és állítsátok be az értékét false ra 
    */

        const isRaining = false;

    /*
        készítsetek egy funkciót 0 paraméterrel és azzal a névvel, hogy detectRain
    */

    const detectRain = () => {
        
        if (isRaining) {
            console.log('esik az eső')
        } else { 
            console.log('nem esik az eső')
         }
    }

    /*
            a funckiónk azt írja ki a console-ba hogy 'esik az eső', ha az isRaining váltózó értéke true, 
            ha az isRaining változó értéke false azt írja ki, hogy 'nem esik az eső'.
            használjatok if else feltételt
    */

    /*
        készítsetek két változót az egyik változónk neve legyen x , a másik pedig y ,
        mindkettőnek egy szám értéket adjatok.
    */
    const x = 7;
    const y = 5;

    /* 
        készítsetek egy  változót osszeg néven amibe számoljátok ki az x és az y összegét.
    */
    const osszeg = x + y;

    /* 
        készítsetek egy  változót kulonbseg néven amibe számoljátok ki az x és az y külonbségét.
    */

    const kulonbseg = x - y;

    /*
        készítsetek egy  változót hanyados néven amibe számoljátok ki az x és az y hányadosát.
    */

    const hanyados = x / y;

    /*
        készítsetek egy  változót szorzat néven amibe számoljátok ki az x és az y szorzatát.
    */

    const szorzat = x * y;

    const imageOne = useRef(null);
    const imageTwo = useRef(null);
    const imageThree = useRef(null);

    const colors = [
        {color: 'yellow'},
        {color:'red'},
        {color:'blue'},
        {color:'purple'}
    ]

    const [circles, setCircles] = useState([
        {color:'beige', label:'1.kör'},
        {color:'tomato', label:'2.kör'}
    ])

    const onCircleClick = (circleIndex) => {
        console.log(circleIndex)

        if (circleIndex === 0) {console.log('első kör')}
        if (circleIndex === 1) {console.log('2.kör')}
    }

    const onRectAngleClick = () => {
        
    }

    const onButtonClick = (imgSrc, imageIndex) => {
        
        if (imageIndex === 1) {
        imageOne.current.setAttribute('src', imgSrc)
        }

        if (imageIndex === 2) {
        imageTwo.current.setAttribute('src', imgSrc)
        }

        if (imageIndex === 3) {
        imageThree.current.setAttribute('src', imgSrc)
        }
    }


    window.addEventListener(
        "scroll",
        () => {
        document.body.style.setProperty(
         "--scroll",
        window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
        );
    },
  false
);


    return(
        <section className='screen-training'>
            <div className='container'>
                <div>
                    <h2>Esődetektor</h2>
                    <button className='button' onClick={() => detectRain()}>
                        Esik vagy nem?
                    </button>
                </div>
                <div className='rectangles'>
                    {colors.map((color, colorIndex) =>
                    <div className={'rectangle ' + color.color} onClick={() => onRectAngleClick(color.color)}> {colorIndex + 1} </div>
                    )}
                </div>
                <div className='task-2'>
                    {circles.map((circle, circleIndex) =>
                    <div className={'circle ' + circle.color} onClick={() => onCircleClick(circleIndex)}>
                        {circle.label}
                    </div>
                    )}
                </div>
                <div className='task-3'>
                    <div className='feature'>
                        <img src='img/plball.jpg' ref={imageOne}/>
                        <h3>
                            headline
                        </h3>
                        <p>
                            description
                        </p>
                        <button className='button' onClick={() => onButtonClick('img/puma.jpg', 1)}>
                            Gomb
                        </button>
                    </div>
                    <div className='feature'>
                        <img src='img/puma.jpg' ref={imageTwo}/>
                        <h3>
                            headline
                        </h3>
                        <p>
                            description
                        </p>
                        <button className='button' onClick={() => onButtonClick('img/molten.jpg')}>
                            Gomb
                        </button>
                    </div>
                    <div className='feature'>
                        <img src='img/puma.jpg' ref={imageThree}/>
                        <h3>
                            headline
                        </h3>
                        <p>
                            description
                        </p>
                        <button className='button' onClick={() => onButtonClick('img/plball.jpg')}>
                            Gomb
                        </button>
                    </div>
                </div>
                <div className='task-4'>
                        <ul>
                            <li>
                                <img src='img/auckland.jpg'/>
                                <div className='li-content'>
                                    <h2>
                                        Headline
                                    </h2>
                                    <p>
                                    "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <img src='img/auckland.jpg'/>
                                <div className='li-content'>
                                    <h2>
                                        Headline
                                    </h2>
                                    <p>
                                        Description
                                    </p>
                                </div>
                            </li>
                            <li>
                                <img src='img/auckland.jpg'/>
                                <div className='li-content'>
                                    <h2>
                                        Headline
                                    </h2>
                                    <p>
                                        Description
                                    </p>
                                </div>
                            </li>
                        </ul>
                </div>
                <SimpleCalculator/>
            </div>
            <div className='task-5'>
                    <div className='cover' style={{backgroundImage:'url(img/trainingcover.jpg)'}}>
                        <h2> Lorem Ipsum </h2>
                        <button>
                            Tovább
                        </button>
                    </div>
                </div>
        </section>
    )

}

export default Training;
import './style.css';

function Contact () {

    const contactInformation = [
        {id:'Cím:  ', description:'2600 Vác, Bimbó u. 16.'},
        {id:'Nyitvatartás:  ', description:'Hétfőtől Péntekig, 08:00 - 16:00'},
        {id:'Telefon:  ', description:'+3670234779'},
        {id:'E-mail:  ', description:'keresettaferi@gmail.com'}

    ]

    return(
        <section className='contact-screen' style={{backgroundImage:'url(/img/contactscreenbg.jpg)'}}>
            <div className='container'>
                <h1 className='title'>
                    Kapcsolat
                </h1>
                <div className='contact-information-row'>
                    <div className='contact-information-box'>
                        <img src='/img/logo.jpg' height={72} width={80}/>
                        {contactInformation.map(contactInformation =>
                            <p className='contact-information'> 
                                {contactInformation.id}
                                {contactInformation.description}
                            </p>
                        )}
                    </div>
                    <div className='contact-screen-description'>
                        <h1 className='headline'>
                            KÉRDÉS ESETÉN KÉRJÜK VEGYE FEL VELÜNK A KAPCSOLATOT A MEGADOTT ELÉRHETŐSÉGEKEN KERESZTÜL!
                        </h1>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Contact;

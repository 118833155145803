import { useState, useEffect } from 'react';
import './style.css'
import { Link } from 'react-router-dom';
import NewEmployee from '../../components/NewEmployee';


const deafultEmployees = [
    {firstName:'Tom', lastName:'Jerry', email:'tom@jerry.com'},
    {firstName:'Peter', lastName:'Jackson', email:'peter@jackson.com'},
    {firstName:'Brad', lastName:'Pitt', email:'brad@pitt.com'},
    {firstName:'Dua', lastName:'Lipa', email:'dua@lipa.com'},
    {firstName:'Bud', lastName:'Spencer', email:'bud@spencer.com'},
    {firstName:'Dua', lastName:'Spencer', email:'dua@spencer.com'}

];


function Dashboard () {

    const [filteredEmployee, setFilteredEmployee] = useState(deafultEmployees);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [showUserForm, setShowUserForm] = useState(false);
    const [employees, setEmployees] = useState(deafultEmployees);


    const onFilteredEmployee = (event) => {


        // start new filtered list with all the employees
        let newFilteredList = employees;

        // filter list by the first name
        if (firstName ) {
            newFilteredList = employees.filter(employee => 
                employee.firstName.toLowerCase().includes(firstName.toLowerCase().trim()));
        }

        // filter list by the last name
        if (lastName) {
            newFilteredList = newFilteredList.filter(employee => 
                employee.lastName.toLowerCase().startsWith(lastName.toLowerCase().trim()));
        }


       setFilteredEmployee(newFilteredList);
        console.log(firstName,lastName,newFilteredList);
    };

    const addNewEmployee = (formData) => {
        // add new employee data to existing employees
        setEmployees([...employees, formData]);

        //show users table, hide +newemployee form
        setShowUserForm(false);
    }
    /*  after set new employee,
        run search algorithm, if the value of employees variable is changing
        example: new employee added
    */
    useEffect(onFilteredEmployee, [employees])

    return(
        <div className='dashboard-screen'>
            <aside className='dashboard-menu'>
                <Link to={'/dashboard'} className='button'>Users</Link>
                <button className='button'>Absences</button>
                <button className='button'>Settings</button>
            </aside>
            <div className='main-content'>

                { showUserForm ? <NewEmployee addNew={addNewEmployee} /> : 
                    <>
                        <div className='users-headline'>
                            <h1 className='headline'> Users </h1>
                            <button onClick={() => setShowUserForm(true)}> 
                                + New employee 
                            </button>
                        </div>
                        <form className='user-filters'>
                            <div className='form-group'>
                                <label> First Name </label>
                                <input 
                                    type='text'
                                    onChange={event => setFirstName(event.target.value)}
                                    value={firstName}    
                                >
                                    
                                </input>
                            </div>
                            <div className='form-group'>
                                <label> Last Name </label>
                                <input 
                                    type='text'
                                    onChange={event => setLastName(event.target.value)}
                                    value={lastName}
                                >
                                </input>
                            </div>
                            <div className='form-group'>
                                <button className='button' onClick={(event) => {event.preventDefault(); onFilteredEmployee(event)}}> 
                                    Search 
                                </button>
                            </div>
                        </form>
                        <div className='dashboard'>
                            <table className='demo-table'>
                                <thead>
                                <tr>
                                    <th> First Name </th>
                                    <th> Last Name </th>
                                    <th> E-mail </th>
                                    <th> Actions </th>
                                </tr>
                                </thead>
                                <tbody>
                                { filteredEmployee.map(employee => 
                                <tr>
                                    <td> {employee.firstName} </td>
                                    <td> {employee.lastName} </td>
                                    <td> {employee.email} </td>
                                    <td> 
                                        <button className='button'>
                                            Absences
                                        </button>
                                        <button className='button'>
                                            Create Absences
                                        </button>
                                    </td>
                                </tr>
                                )}
                                </tbody>
                            </table> 
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default Dashboard;